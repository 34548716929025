<template>
    <base-card>
        <v-card-text>
            <div
                class="d-flex justify-space-between align-center flex-wrap m-3"
            >
                <div class="flex-1 d-flex align-center mr-4">
                    <v-img contain :max-width="($vuetify.breakpoint.xsOnly) ? 50 : 80" :src="`/assets/gaa/teams/icons/${fixture.homeLogo}.WEBP`" :alt="fixture.homeTeam" class="img_border"></v-img>
                    <div class="flex-grow-1 ml-2">
                        <p class="font-weight-semi ma-0 ">
                            {{ fixture.homeTeam }}
                        </p>
                    </div>
                </div>
                <div v-if="(!$vuetify.breakpoint.xsOnly)" class="flex-1 text-center">
                    <p class="font-weight-semi ma-0">
                            {{ fixture.matchdayLabel }}
                    </p>
                    <p class="ma-0">
                            {{ fixture.date }}
                    </p>
                    <span class="text--disabled">{{ fixture.venue }}</span>
                    <div class="flex-grow-1">
                        <p class="font-weight-semi ma-0">
                            {{ fixture.time }}
                        </p>
                    </div>
                </div>
                <div class="flex-1 d-flex align-center ml-">
                    <div class="flex-grow-1 text-right mr-2">
                        <p class="font-weight-semi ma-0 ">
                            {{ fixture.awayTeam }}
                        </p>
                    </div>
                    <v-img contain :max-width="$vuetify.breakpoint.xsOnly ? 50 : 80" :src="`/assets/gaa/teams/icons/${fixture.awayLogo}.WEBP`" :alt="fixture.awayTeam" class="img_border"></v-img>
                </div>
                <div v-if="$vuetify.breakpoint.xsOnly" class="flex-1 mt-4 text-center" style="min-width: 263px">
                    <div class="flex-grow-1">
                        <p class="font-weight-semi ma-0">
                            Matchday {{ fixture.matchday }}
                        </p>
                    </div>
                    <span class="text--disabled">{{ fixture.venue }}</span>
                </div>
            </div>
            <v-divider class="mt-5 mb-3" />
            <div class="d-flex flex-wrap">
                    <v-btn
                        v-if="footerBtnColor"
                        class="ma-2 mr-0"
                        :color="fixture.homeColour"
                        small
                        dark
                        @click="toggleExpand(1)"
                        :class="result == 1 ? 'active' : ''"
                    >
                        {{ fixture.homeTeam }}
                    </v-btn><v-spacer />
                    <v-btn
                        v-if="footerBtnColorTwo"
                        class="ma-2 ml-0 mr-0"
                        small
                        dark
                        @click="toggleExpand(-1)"
                        :class="result == -1 ? 'active' : ''"
                    >
                        Draw
                    </v-btn><v-spacer />
                    <v-btn
                        v-if="footerBtnColorTwo"
                        class="ma-2 ml-0"
                        :color="fixture.awayColour"
                        small
                        dark
                        @click="toggleExpand(2)"
                        :class="result == 2 ? 'active' : ''"
                    >
                        {{ fixture.awayTeam }}
                    </v-btn>
                <v-expand-transition>
                    <div v-show="!showScoreSelection" class="text-center" style="width: 100%">
                        <small v-if="this.result != -1">{{ resultText }}</small>
                        <p class="pb-2 mt-2" v-else>{{ resultText }}</p>
                    </div>
                </v-expand-transition>
                <v-expand-transition>
                    <div v-show="showScoreSelection" class="text-center" style="width: 100%">
                        <span v-for="button in winningMargins" :key="button.value">
                            <v-btn
                                v-if="button.value != 0"
                                class="ma-1"
                                depressed
                                :color="selectedColour"
                                dark
                                small
                                @click="winningMarginSelected(button)"
                                :class="marginButtonClass(button.value)"
                            >
                                {{ button.label }}
                            </v-btn>
                        </span>
                    <div class="text-center" style="width: 100%" :id="`${fixture.id}-result-label`">
                        <p class="pb-2 mt-2" v-if="this.winner">{{ selectionText }}</p>
                        <small v-else>{{ selectionText }}</small>
                    </div>
                    <v-dialog
                        v-model="dialog"
                        max-width="290"
                        >
                        <v-card>
                            <v-card-title class="text-h5">
                                Select margin
                            </v-card-title>

                            <v-card-text>
                                <v-radio-group
                                    v-model="dialogValue"
                                    column
                                >
                                    <v-radio
                                        v-for="number in additionalNumbers"
                                        :key="number.value"
                                        :label="number.label"
                                        :value="number"
                                        @click="dialogNumberSelected"
                                    ></v-radio>
                                </v-radio-group>
                            </v-card-text>

                            <v-card-actions>
                            <v-spacer></v-spacer>

                            <v-btn
                                dark
                                text
                                @click="dialog = false"
                            >
                                Cancel
                            </v-btn>

                            <v-btn
                                id="confirm_margin_btn"
                                color="green darken-1"
                                text
                                @click="winningMarginSelected(dialogValue);dialog = false"
                            >
                                Confirm
                            </v-btn>
                            </v-card-actions>
                        </v-card>
                        </v-dialog>
                    </div>
                </v-expand-transition>
            </div>
        </v-card-text>
    </base-card>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
    props: {
        // Floating Button icon Color
        fixture: {
            type: Object,
            required: true
        },
        progressbarColor: {
            type: String,
            default: 'warning'
        },
        footerBtn: {
            type: String,
            default: 'Chat'
        },
        footerBtnTwo: {
            type: String,
            default: 'Profile'
        },
        footerBtnColor: {
            type: String,
            default: 'red'
        },
        footerBtnColorTwo: {
            type: String,
            default: 'blue'
        },
        editing: {
            type: Boolean,
            default: false
        },
        prediction: {
            type: Object,
            required: false
        }
    },
    mounted(){
        if(this.prediction){
            if(this.prediction.result){
                this.toggleExpand(this.prediction.result);
            }
            if(this.prediction.margin){
                this.winningMarginSelected({"value": Number(this.prediction.margin), "label": `${this.prediction.margin}`});
            }
        }
    },
    data() {
        return {
            result: 0,
            winner: null,
            margin: null,
            marginText: '',
            dialog: false,
            dialogValue: null,
            additionalNumbers: Array.from({ length: 30 - 9 + 1 }, (v, i) => ({
                label: (i + 9).toString(),
                value: i + 9 
            })),
            winningMargins: [{"value": 1, "label": "1"},{"value": 2, "label": "2"},{"value": 3, "label": "3"},{"value": 4, "label": "4"},
            {"value": 5, "label": "5"},{"value": 6, "label": "6"},{"value": 7, "label": "7"},{"value": 8, "label": "8"},{"value": -2, "label": "+"}
            ]
        }
    },
    computed: {
        ...mapGetters(["hasMadeSelection", "getGameWeekSelection", "getCompetitionPredictionsOpen"]),
        selectedColour() {
           return this.result == 2 ? this.fixture.awayColour : this.fixture.homeColour;
        },
        selectionText() {
            if(this.winner == 0){
                return "Draw Game";
            }
            else if(this.winner && this.margin){
                return `${this.winner} win by ${this.marginText} points`;
            } 
            else if(this.winner){
                return `${this.winner} win`
            }
            else {
                return 'Choose margin of victory';
            }
        },
        resultText() {
            if(this.result == -1){
                return 'Draw Game'
            }
            else {
                return 'Make your prediction'
            }
        },
        showScoreSelection(){
            return this.result && this.result != -1
        },
        predictionText(){
            let prediction = this.$store.getters['getGameWeekSelection'].find(selection => selection.fixtureEnt.id == this.fixture.id)
            if(!prediction) return 'No selection made'
            if(prediction.matchResult == 'HOME_WIN'){
                return this.fixture.homeTeam + ` ${prediction.winningMargin.label}`;
            } else if(prediction.matchResult == 'AWAY_WIN'){
                return this.fixture.awayTeam + ` ${prediction.winningMargin.label}`;;
            }
            return 'Draw';
        },
        predictionColor(){
            let prediction = this.$store.getters['getGameWeekSelection'].find(selection => selection.fixtureEnt.id == this.fixture.id)
            if (!prediction) return 'colour: grey';
            if(prediction.matchResult == 'HOME_WIN'){
                return `color: ${this.fixture.homeColour}`;
            } else if(prediction.matchResult == 'AWAY_WIN'){
                return `color: ${this.fixture.awayColour}`;
            }
            return 'color: grey';
        }
    },
    methods: {
        toggleExpand(result) {
            if(this.result == result){
                this.expand = !this.expand;
                this.result = 0;
                this.winner = null;
                this.margin = null;
                this.$emit('resultUnselected', {'fixtureId': this.fixture.id, 'result': 0})
            } else {
                this.$emit('resultSelected', {'fixtureId': this.fixture.id, 'result': result});
                this.expand = true;
                this.result = result;
                if(result != -1){
                    if(this.margin){
                        this.winningMarginSelected(this.margin);
                    }
                    this.winner = result == 1 ? this.fixture.homeTeam : this.fixture.awayTeam;
                } else {
                    this.winner = 0;
                    this.result = result;
                    this.winningMarginSelected(0);
                }
            }
        },
        dialogNumberSelected(){
            document.getElementById(`confirm_margin_btn`).scrollIntoView({ behavior: 'smooth', block: 'end' })
        },
        marginButtonClass(value){
            if(this.margin > 8 && value == -2){
                return 'active';
            } else if(this.margin == value){
                return 'active';
            } else {
                return '';
            }
        },
        winningMarginSelected(margin){
            if(margin.value == -2){
                this.dialog = true;
                return;
            }
            else if(margin.value==-1){
                return;
            }
            if(this.margin === margin.value){
                this.margin = null;
                this.marginText = '';
                this.$emit('marginUnselected', {"fixtureId" : this.fixture.id});
            } else {
                this.margin = margin.value;
                this.marginText = margin.label;
                this.$emit('marginSelection', {"margin": margin.value, "fixtureId" : this.fixture.id});
                this.$emit('marginSelected');
            }
        }
    }
}
</script>

<style scoped>
 .active {
    outline: none;
    border-color: #9ecaed;
    box-shadow: 0 0 9px 1px #9ecaed;
    /* box-shadow: 0 0 6px 3px #44a0ea; */
 }

.img_border {
    border-style: solid;
    border-width: 2px;
}
</style>